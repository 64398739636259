import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// sections
import Content from './sections/Content';
import { PageProps } from 'gatsby';

const PrivacyPolicy: FC<PageProps> = ({ location }) => {
  return (
    <PageLayout
      metaData={{
        title: 'Privacy Policy - Seedium',
        description:
          'Check out the Privacy Policy to learn what information of yours will be collected by Seedium when you access our website.',
        href: location.href,
      }}
    >
      <Intro
        title='Privacy Policy'
        description='Our Privacy Policy determines the procedure for receiving, storing, processing, and using information obtained during the use of the site.'
        icon='privacyPolicyDrawing'
      />
      <Content />
    </PageLayout>
  );
};

export default PrivacyPolicy;
