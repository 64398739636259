import { FC } from 'react';
// components
import Typography from 'src/components/Typography';
import TextContent from 'src/components/TextContent';
import Link from 'src/components/Link';
import List from 'src/components/List';

import * as style from 'src/components/TextContent/TextContent.module.scss';

const Content: FC = () => {
  return (
    <TextContent>
      <Typography variant='h3' className={style.title}>
        General
      </Typography>
      <Typography variant='body'>
        The protection of your privacy is one of our key priorities.
        <br /> Thus, we would like to explain what information of yours will be
        collected by <strong>Seedium</strong> when you access our website at{' '}
        <Link href='https://seedium.io' target='_blank' rel='noreferrer'>
          seedium.io
        </Link>{' '}
        (the “Website”) or communicate with us through email, phone, or via
        other means.
      </Typography>
      <Typography variant='body'>
        This Privacy Policy also describes how and why we process your personal
        data, how you can control the collection, amendment, and/or deletion of
        your sensitive information, and how to contact us if it is needed.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Your Consent
      </Typography>
      <Typography variant='body'>
        Please read this Privacy Policy carefully. By using the Website, you
        agree to the terms of our Privacy Policy and the processing of your
        personal information for the purposes specified in this document.
      </Typography>
      <Typography variant='body'>
        If you disagree with the terms of this Privacy Policy, please do not
        browse and live the Website. If you wish to stop receiving our
        advertising letters, please click on the ‘unsubscribe’ button within the
        marketing email you have received from <strong>Seedium</strong>.
      </Typography>
      <Typography variant='body'>
        This Website is not intended to offer any services directly to children.
        We do not knowingly collect information from children. If you are under
        the age of 16 and want to get in touch with us, please, send us written
        consent from your parents or guardians to process your personal
        information.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Links
      </Typography>
      <Typography variant='body'>
        The Website might contain links, plug-ins, and applications to various
        third-party websites. Clicking on those links or enabling those
        connections may allow third parties to collect or share data about you.{' '}
        <strong>Seedium</strong> has no control over and is not responsible for
        the content, privacy policies, or reliability of such third-party
        connections. When you leave the Website, <strong>Seedium</strong>{' '}
        encourages you to read the privacy notice and privacy policy of every
        website you visit.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Collection of the data
      </Typography>
      <Typography variant='body'>
        <strong>Seedium</strong> has created this Website to communicate with
        casual visitors, existing and potential clients, and companies
        interested in our services as well as to provide information about our
        company and the IT outsourcing industry.
      </Typography>
      <Typography variant='body'>
        <strong>Seedium</strong> collects, stores, and uses the data that is
        essential for providing the visitors of the Website the maximum benefit
        and to better understand what content is of interest to them.
      </Typography>
      <Typography variant='body'>
        <strong>Seedium</strong> may also use your personal data for secondary
        purposes closely related to the mentioned above, in circumstances where
        you would reasonably expect such use. In particular,{' '}
        <strong>Seedium</strong> collects your information when you
      </Typography>
      <List
        list={[
          'submit your data through the contact form on the Website',
          <>
            make your personal data available for <strong>Seedium</strong> by
            phone, email, in messengers, etc.
          </>,
        ]}
      />
      <Typography variant='body'>
        The following data might be collected during your Website session:
      </Typography>
      <List
        list={[
          <>
            Your email address, phone number, name, surname, company name, job
            title, postal address, and other information you affirmatively
            choose to submit to <strong>Seedium</strong> via contact forms or
            email messages.
          </>,
          'Data about your behavior on the Website during your visit and information about how you navigate it.',
          'Your IP address, date/time stamp, domain name, country/state, and information about your location.',
          'Statistics, such as particular pages you visit, number of unique Website entries, the browser from which you visit the Website, your screen resolution, and the platform you use.',
        ]}
      />
      <Typography variant='body'>
        During our marketing, advertisement, and/or recruitment activities, as
        well as for communicating our services and solutions to current or
        potential clients, vendors, and/or partners <strong>Seedium</strong> may
        collect and process the following data:
      </Typography>
      <List
        list={[
          'Information collected from you or third parties or from public sources, including your email address, phone number, name, surname, company name, job title, postal address, etc.',
          <>
            Information you provide to <strong>Seedium</strong> when using our
            services or information which is generated in the course of the use
            of those services (including the timing, frequency, and pattern of
            service use).
          </>,
          'Activities in relation to the sales process, data on our communications with you, content and results of such communications and metadata associated with the communication, and our internal notes about you.',
          'Information you provide to participate in our events, live or web conferences.',
          'Publicly available professional information about you.',
        ]}
      />
      <Typography variant='body'>
        <strong>Seedium</strong> may also collect, store and use any other
        personal data that you choose to send to us. In cases where it is
        specifically stated that some data is not mandatory, you are free not to
        submit this data.
      </Typography>
      <Typography variant='body'>
        In some circumstances, such as recommendations for potential employment,
        etc., <strong>Seedium</strong> may be provided with your personal
        information by third parties. In such cases, we take reasonable steps to
        ensure that you are made aware of the data provided to us by the third
        party.
      </Typography>
      <Typography variant='body'>
        Other than as required by law, <strong>Seedium</strong> does not collect
        any special categories of personal information about you including
        details about your race or ethnicity, religious or philosophical
        beliefs, sex life, sexual orientation, political opinions, health and
        biometric data, criminal convictions, and offences, etc.
      </Typography>
      <Typography variant='body'>
        <strong>Seedium</strong> may collect aggregated data such as statistical
        or demographic information. The aggregated data may be derived from your
        personal information but is not considered personal information in law
        as it does not directly or indirectly reveal your identity unless{' '}
        <strong>Seedium</strong> combines or connects aggregated data with your
        personal information so that it can directly or indirectly identify you.
      </Typography>
      <Typography variant='body'>
        Your personal data submitted to <strong>Seedium</strong> through the
        Website and personal data we collected from you, third parties or public
        sources will be used (processed) solely for the purposes specified in
        this Privacy Policy.
      </Typography>
      <Typography variant='body'>
        Personal data processed by <strong>Seedium</strong> is not subject to
        profiling and automated decision-making.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Sharing of the data
      </Typography>
      <Typography variant='body'>
        In the course of its business activities <strong>Seedium</strong> may
        disclose your personal information to the following categories of
        recipients:
      </Typography>
      <List
        list={[
          <>
            Professional advisers, third-party services providers, and partners
            who provide data processing services to <strong>Seedium</strong>{' '}
            and/or assist us in operating this Website and/or in our marketing
            and recruiting activities.
          </>,
          <>
            Any competent law enforcement body, regulatory, government agency,
            court or other third parties in order to comply with the law, a
            judicial proceeding, a court order, or another legal process, such
            as in response to a court order or a subpoena or where{' '}
            <strong>Seedium</strong> reasonably believes disclosure is necessary
            (a) as a matter of applicable law or regulation, (b) to exercise,
            establish or defend its legal rights, or (c) to protect your vital
            interests or those of any other person.
          </>,
          'Relevant third parties in case of acquisition of our company or merger with another company.',
          'Any other person with your consent to the disclosure.',
          'Except in those specific, limited situations, without your consent, we will not make any disclosures of non‐public personal information to other companies who may want to sell their products or services to',
        ]}
      />

      <Typography variant='h3' className={style.title}>
        Legal basis
      </Typography>
      <Typography variant='body'>
        Seeduim will use your personal data on the following legal basis under
        the General Data Protection Regulation (GDPR, Regulation (EU) 2016/679
        of the European Parliament and the Council) and other applicable data
        protection legislation:
      </Typography>
      <List
        list={[
          'Legitimate interest',
          'Consent',
          'Other legal bases available under GDPR Article 6',
        ]}
      />
      <Typography variant='body'>
        The data protection practices implemented in <strong>Seedium</strong>{' '}
        are designed to assure a high level of protection for your personal data
        and full compliance with the provisions of GDPR and other applicable
        data protection legislation. We will not use or share your information
        with anyone except as described in this Privacy Policy.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Location of the processing
      </Typography>
      <Typography variant='body'>
        Your personal data may be processed in various locations outside
        European Union and the United States, each having different data
        protection standards. However, we will take all reasonable steps to
        ensure that your data is handled securely and in accordance with the
        highest standards provided for in the GDPR, European Commission
        Implementing Decision (EU) 2021/914 of June 4th, 2021 on standard
        contractual clauses for the transfer of personal data to third countries
        pursuant to GDPR and other applicable data protection legislation, as
        well as this Privacy Policy and industry standards.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Data retention
      </Typography>
      <Typography variant='body'>
        <strong>Seedium</strong> will retain your personal data only as long as
        necessary for the purposes set out in this Privacy Policy, after which
        it will be deleted or archived except to the extent necessary to comply
        with our legal obligations, resolve disputes, and enforce our legal
        agreements and policies.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Your rights
      </Typography>
      <Typography variant='body'>
        You have the following data protection rights:
      </Typography>
      <List
        list={[
          'The right to be informed about the collection and use of your personal data.',
          'The right to request information on the purpose of data processing and information on third parties that have received your personal data.',
          <>
            The right to ask <strong>Seedium</strong> to rectify personal data
            that is incorrect or outdated.
          </>,
          <>
            The right to restrict processing, meaning that you may request
            deletion of your personal data or ask <strong>Seedium</strong> to
            stop processing your data at any time.
          </>,
          'The right to request portability of your personal data.',
          'The right to raise a justified objection to the processing of your personal data.',
          <>
            The right to withdraw consent for processing data at any time if you
            have previously provided <strong>Seedium</strong> with consent for
            the processing of your personal data.
          </>,
          'The right to file a complaint with a regulatory authority.',
        ]}
      />
      <Typography variant='body'>
        If you wish to access or amend any personal data we hold about you, or
        to request that we delete any information about you may contact us at{' '}
        <Link href='mailto:privacy@seedium.io'>privacy@seedium.io</Link>.
      </Typography>
      <Typography variant='body'>
        You may also object to processing of your personal data for direct
        marketing purposes or ask us to restrict processing of your personal
        data. If you believe your right to privacy granted by applicable data
        protection laws has been infringed upon, please contact us at the
        abovementioned email.
      </Typography>
      <Typography variant='body'>
        <strong>Seedium</strong> will not charge any fee for your access request
        but may charge an administrative fee for providing a copy of your
        personal information.
      </Typography>
      <Typography variant='body'>
        In order to protect your personal data, <strong>Seedium</strong> may
        require identification from you or your authorized representatives
        before releasing the requested information.
      </Typography>
      <Typography variant='body'>
        We promptly consider each of your requests and provide a substantive
        response either by taking the requested action or by explaining why your
        request cannot be executed on our side. You may also exercise your
        rights by sending a request to the contacts listed in the contact
        details section below.
      </Typography>
      <Typography variant='body'>
        Please keep in mind that if we are reaching you by email or
        correspondence, you may always unsubscribe from our mailing
        advertisement lists at any time by contacting by email at{' '}
        <Link href='mailto:privacy@seedium.io'>privacy@seedium.io</Link> or by
        using the ‘unsubscribe’ button within the marketing email you have
        received from <strong>Seedium</strong>.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Processing personal data of job applicants
      </Typography>
      <Typography variant='body'>
        As part of our recruitment process, <strong>Seedium</strong> collects
        and processes personal data provided directly by job applicants, their
        referees, and recruitment companies working on our behalf. Given the
        nature of our HR and recruiting activities, we need to process data to
        collaborate with you on possible job opportunities.
      </Typography>
      <Typography variant='body'>
        <strong>Seedium</strong> has a legitimate interest in processing
        personal data during the recruitment process and for record-keeping
        purposes. Processing data of job applicants allows us to manage the
        recruitment process, assess and confirms suitability for employment.
      </Typography>
      <Typography variant='body'>
        In this respect, <strong>Seedium</strong> may collect a range of
        information about you that includes:
      </Typography>
      <List
        list={[
          'Name, address, and contact details, such as email address, telephone number, skype, and links to social network profiles',
          'Details of qualifications, skills, experience, and employment history',
          'Information about the current level of remuneration, including benefit entitlements',
          'Information about your entitlement to work in the EU or US if required by our clients',
        ]}
      />
      <Typography variant='body'>
        The data may be extracted from application forms, CVs (resumes), or
        through interviews or other means of assessment.
      </Typography>
      <Typography variant='body'>
        <strong>Seedium</strong> may share your personal data with current or
        potential or existing clients, vendors and/or partners (in this case,
        you will be informed separately) as well as with other third parties
        mentioned in this Privacy Policy.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Cookies
      </Typography>
      <Typography variant='body'>
        This Website uses cookies to collect data and provide visitors with a
        more user-friendly browsing experience.
      </Typography>
      <Typography variant='body'>
        Cookies are selected pieces of data that are stored on your computer to
        help a website recognize you on your next visit. Cookies automatically
        collect data that isn’t personally identifiable, such as preferred
        internet browser, operating system, domain name, website interactions,
        visit time, location, and most-viewed pages per visit.
      </Typography>
      <Typography variant='body'>
        By accepting our Privacy Policy and remaining on the Website, you agree
        with our Website’s use of cookies.
      </Typography>
      <Typography variant='body'>
        You can set your browser to refuse all or some browser cookies or to
        alert you when internet sites set or access cookies. If you disable or
        refuse cookies some parts of the Website may become inaccessible or not
        function properly.
      </Typography>

      <Typography variant='h3' className={style.title}>
        Contact details
      </Typography>
      <Typography variant='body'>
        If you have any questions or comments regarding this Privacy Policy,
        please send an email to{' '}
        <Link href='mailto:privacy@seedium.io'>privacy@seedium.io</Link> with
        the subject line “Privacy Policy Request”.
        <br />
        <strong>Seedium</strong> contact details:
      </Typography>
      <List
        list={[
          <>
            <Link
              href='https://www.google.com.ua/maps/@50.4435542,30.5221984,18z'
              target='_blank'
              rel='noreferrer'
            >
              Address: 58 Yaroslavska str., Kyiv, Ukraine.
            </Link>
          </>,
          <>
            Email: <Link href='mailto:info@seedium.io'>info@seedium.io</Link>
          </>,
        ]}
      />

      <Typography variant='h3' className={style.title}>
        Version of the Policy
      </Typography>
      <Typography variant='body'>
        This version of the Privacy Policy was published on 29.08.2022.
        <br />
        <strong>Seedium</strong> reserves the right to make changes to this
        Privacy Policy at its sole discretion and at any time.
        <br />
        You are advised to review this Privacy Policy periodically for any
        changes. Changes to this Privacy Policy are effective when they are
        posted on this page of the Website.
        <br />
        By continuing to use the Website, you accept those changes.
      </Typography>
    </TextContent>
  );
};

export default Content;
